<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="600px">
    <v-card>
      <material-card
        :title="$t('prepaidCard.add')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <v-flex
                xs12
                sm6
                md6>
                <v-text-field
                  :rules="[requiredValue, isGreaterThanZero]"
                  v-model="quantity"
                  :label="$t('dashboard.accountCreatedInYearReport.numberOfAccount')"
                  :name="$t('dashboard.accountCreatedInYearReport.numberOfAccount')"
                  type="text"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6>
                <v-text-field
                  :rules="[requiredValue, isGreaterThanZero]"
                  v-model="points"
                  :label="$t('prepaidCard.points')"
                  :name="$t('prepaidCard.points')"
                  type="text"
                  class="required"
                />
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'
import functionUtils from 'utils/functionUtils'
export default {
  data () {
    return {
      valid: false,
      isShowModal: false,
      isLoading: false,
      quantity: null,
      points: null
    }
  },
  methods: {
    /**
     * Show modal confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        let filter = {
          quantity: this.quantity,
          points: this.points
        }
        this.isLoading = true
        this.CREATE_PREPAID_CARD(filter).then(
          function () {
            this.$emit('onCreatePrepaidCardSuccess')
            this.isShowModal = false
            this.quantity = null
            this.isLoading = false
          }.bind(this)
        )
      }
    },
    /**
     * Required value
     */
    requiredValue (value) {
      if (value !== null && value !== undefined && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    /**
     * Check greater than zero
     */
    isGreaterThanZero (value) {
      value = functionUtils.formatInteger(value)
      if (value > 0) {
        return true
      }
      return this.$t('users.valueMustBeGreaterThanZero')
    },
    onShowModal: function () {
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
    },
    ...mapActions(['ON_SHOW_TOAST', 'CREATE_PREPAID_CARD'])
  }

}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
