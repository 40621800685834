<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('common.search')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md3>
                  <v-text-field
                    v-model="prepaidCardSearch.searchText"
                    :label="$t('common.search')"
                    clearable
                    @keydown.enter="onSearch($event)"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dateFormatted"
                        :label="$t('history.dateAction')"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        clearable
                        @blur="date = parseDate(dateFormatted)"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="dateSearch"
                      no-title
                      @input="menu = false"
                    />
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md3>
                  <v-select
                    v-model="prepaidCardSearch.statusValue"
                    :items="prepaidCardStatusList"
                    :label="$t('user.status')"
                    clearable
                  >
                    <template
                      slot="selection"
                      slot-scope="data"
                      chips
                    >
                      {{ $t(data.item.text) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      <!-- HTML that describe how select should render items when the select is open -->
                      {{ $t(data.item.text) }}
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSearch($event)"
              >
                <span class="btn-label mr-3">
                  <i class="glyphicon glyphicon-search" />
                </span>
                <span style="text-transform: none;">{{
                  $t("common.search")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('prepaidCard.list')"
          color="green"
          flat
          full-width
        >
          <template>
            <!-- Create prepaid card -->
            <v-btn
              v-if="isAdmin() || isSuperAdmin() || isAccountant()"
              color="success"
              dark
              style="float: right;"
              @click="onShowCreatePrepaidCardModal"
            >
              {{ $t('common.add') }}
            </v-btn>
            <!-- Lock and Unlock -->
            <div style="float: right;">
              <v-menu
                offset-y
                transition="slide-y-transition"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="deep-orange darken-1"
                    dark
                    v-on="on">
                    <v-icon class="mr-2">mdi-lock-open</v-icon>
                    <span style="text-transform: none;">{{
                      $t('prepaidCard.lockAndUnlockTitle')
                    }}</span>
                  </v-btn>
                </template>
                <v-card>
                  <!-- Print selected -->
                  <v-list>
                    <v-list-tile @click="updateStatusPrepaidCardSelect(prepaidCardStatus.LOCK)">
                      <v-list-tile-action>
                        <v-icon
                          color="error">mdi-lock</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-title>{{ $t('prepaidCard.lockCardSelect') }}</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="updateStatusPrepaidCardSelect(prepaidCardStatus.UNLOCK)">
                      <v-list-tile-action>
                        <v-icon
                          color="primary">mdi-lock-open-variant</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-title>{{ $t('prepaidCard.unlockCardSelect') }}</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
            <!-- Print cards selected -->
            <div style="float: right;">
              <v-menu
                offset-y
                transition="slide-y-transition"
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="indigo"
                    dark
                    v-on="on">
                    <v-icon class="mr-2">mdi-printer</v-icon>
                    <span style="text-transform: none;">{{
                      $t('prepaidCard.printCard')
                    }}</span>
                  </v-btn>
                </template>
                <v-card>
                  <!-- Print selected -->
                  <v-list>
                    <v-list-tile @click="onShowModalInputPrepaidCardInfo(false)">
                      <v-list-tile-action>
                        <v-icon
                          color="primary">mdi-select</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-title>{{ $t('prepaidCard.printCardSelected') }}</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="onShowModalInputPrepaidCardInfo(true)">
                      <v-list-tile-action>
                        <v-icon
                          color="primary">mdi-check-all</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-title>{{ $t('prepaidCard.printAllPrepaidCard') }}</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
            <!-- Recharge point multiple -->
            <v-btn
              v-if="isAccountant()"
              color="success"
              dark
              style="float: right;"
              @click="rechargePointsInBulk"
            >
              {{ $t('prepaidCard.rechargePointsInBulk') }}
            </v-btn>
          </template>
          <v-data-table
            :no-data-text="$t('common.noDataAvailable')"
            :headers="prepaidCardHeaders"
            :items="prepaidCardList"
            :single-select="true"
            v-model="selected"
            hide-actions
            select-all
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              v-slot:items="props"
            >
              <td>
                <v-checkbox
                  v-model="props.selected"
                  primary
                  hide-details />
              </td>
              <td
                v-for="(header, index) in prepaidCardHeaders"
                :key="header.value + index">
                <div
                  v-if="header.value == 'actions'"
                  class="text-xs-center">
                  <v-tooltip
                    v-if="isAccountant()"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="props.item.isLoading"
                        :disabled="props.item.status === prepaidCardStatus.LOCK"
                        class="mx-2"
                        fab
                        small
                        color="success"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="onShowModalChargePoints(props.item)">
                        <v-icon dark>mdi-plus-thick</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('users.addPoints') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="isAccountant()"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="props.item.isLoading"
                        :disabled="props.item.status === prepaidCardStatus.LOCK || props.item.points === 0 || props.item.active_date === null"
                        class="mx-2"
                        fab
                        small
                        color="teal"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="showModalRefundPoints(props.item)">
                        <v-icon dark>mdi-credit-card-refund</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('users.refundPoints') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="(isAccountant() || isAdmin()) && props.item.status === prepaidCardStatus.UNLOCK"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="props.item.isLoading"
                        class="mx-2"
                        fab
                        small
                        color="error"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="showModalUpdatePrepaidCard(props.item.id, prepaidCardStatus.LOCK)">
                        <v-icon dark>mdi-lock</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('prepaidCard.lockCard') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="(isAccountant() || isAdmin()) && props.item.status === prepaidCardStatus.LOCK"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :loading="props.item.isLoading"
                        class="mx-2"
                        fab
                        small
                        color="teal"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="showModalUpdatePrepaidCard(props.item.id, prepaidCardStatus.UNLOCK)">
                        <v-icon dark>mdi-lock-open-variant</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('prepaidCard.unlockCard') }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="header.value == 'isActive'"
                  class="text-xs-center">
                  <v-tooltip
                    v-if="props.item.active_date === null"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="error"
                        dark
                        v-on="on">mdi-close-outline</v-icon>
                    </template>
                    <span>{{ $t('users.state.in_active') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-else-if="props.item.status === prepaidCardStatus.LOCK"
                    top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="error"
                        dark
                        v-on="on">mdi-lock</v-icon>
                    </template>
                    <span>{{ $t('users.status.lock') }}</span>
                  </v-tooltip>
                  <v-tooltip
                    v-else
                    top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="success"
                        dark
                        v-on="on">mdi-check-outline</v-icon>
                    </template>
                    <span>{{ $t('users.state.active') }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-else-if="header.value == 'name'"
                  class="cursor-pointer"
                  @mouseover="onHoverEmail(props.item)"
                  @mouseleave="onLeaveEmail(props.item)">
                  <span
                    class="pb-1"
                    style="border-bottom: 1px dashed #5cb860;">{{ props.item.name }}</span>
                  <div
                    v-show="props.item.isShowTool"
                    class="mt-1"
                    style="display: flex;">
                    <span
                      class="hover-delete-text"
                      style="color: blue;"
                      @click="onShowTransactionHistory(props.item.id)">{{ $t('common.history') }}</span>
                  </div>
                </div>
                <span v-else>{{ props.item[header.value] }}</span>
              </td>
            </template>
          </v-data-table>
        </material-card>
        <pagination
          :total="prepaidCardPaginate.totalPage"
          :current-page="prepaidCardPaginate.currentPage"
          :row-per-page="prepaidCardPaginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <chargePointsModal
      ref="ChargePointsModal"
      @updatePoint="getPrepaidCardList"/>
    <refundPointsModal
      ref="refundPointsModal"
      @updatePoint="getPrepaidCardList"/>
    <createPrepaidCardModal
      ref="createPrepaidCardModal"
      @onCreatePrepaidCardSuccess="getPrepaidCardList"/>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <inputPrepaidCardInfoModal
      ref="inputPrepaidCardInfoModal"
      @receivedPrepaidCardInfo="onGoScreenPrint" />
    <loadingBar :is-loading="isLoading" />
    <!-- transaction history -->
    <transactionHistoryListForUser
      ref="transactionHistoryListForUser"/>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="updatePrepaidCard()"
    />
    <confirm-modal
      ref="updateStatusPrepaidCardSelectModal"
      :title="updateStatusPrepaidCardSelectTitle"
      @onConfirm="confirmUpdateStatusPrepaidCardSelect()"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ChargePointsModal from '../Customer/ChargePointsModal'
import refundPointsModal from '../Customer/RefundPointsModal'
import CreatePrepaidCardModal from './CreatePrepaidCardModal'
import ConfirmModal from 'Components/ConfirmModal'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import RoleType from 'enum/roleType'
import prepaidCardStatus from 'enum/prepaidCardStatus'
import InputPrepaidCardInfoModal from './InputPrepaidCardInfoModal'
import ToastType from 'enum/toastType'
import stringUtils from 'utils/stringUtils'
import moment from 'moment'
import transactionHistoryListForUser from '../History/TransactionHistoryListForUser'
export default {
  components: {
    ChargePointsModal,
    CreatePrepaidCardModal,
    ConfirmModal,
    InputPrepaidCardInfoModal,
    refundPointsModal,
    transactionHistoryListForUser
  },
  data: () => ({
    prepaidCardHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt'
      },
      {
        sortable: false,
        text: 'users.dateCreated',
        value: 'dateCreated'
      },
      {
        sortable: false,
        text: 'prepaidCard.name',
        value: 'name'
      },
      {
        sortable: false,
        text: 'prepaidCard.code',
        value: 'code'
      },
      {
        sortable: false,
        text: 'users.points',
        value: 'points'
      },
      {
        sortable: false,
        text: 'common.state',
        value: 'isActive',
        align: 'center'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    prepaidCardList: [],
    prepaidCardPaginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    prepaidCardPaginateTotalItem: null,
    prepaidCardSearch: {
      searchText: null,
      statusValue: null
    },
    isLoading: false,
    confirmModalTitle: '',
    prepaidCardSelect: {
      id: null,
      status: null
    },
    prepaidCardStatus: prepaidCardStatus,
    selected: [],
    dateFormatted: null,
    menu: false,
    dateSearch: null,
    prepaidCardStatusList: stringUtils.prepaidCardStatusList,
    printAllPrepaidCard: false,
    lockAndUnlockCardIds: [],
    lockAndUnlockCardStatus: null,
    updateStatusPrepaidCardSelectTitle: ''
  }),
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.dateSearch)
    },
    ...mapGetters(['PREPAID_CARD_LIST_DATA', 'GET_SNACK_BAR', 'GET_CURRENT_ACCOUNT'])
  },
  watch: {
    dateSearch (val) {
      this.dateFormatted = this.formatDate(this.dateSearch)
    },
    PREPAID_CARD_LIST_DATA () {
      this.prepaidCardPaginate.currentPage = this.PREPAID_CARD_LIST_DATA.paginate.currentPage
      this.prepaidCardPaginate.totalPage = this.PREPAID_CARD_LIST_DATA.paginate.totalPage
      this.prepaidCardPaginate.rowPerPage = this.PREPAID_CARD_LIST_DATA.paginate.perPage
      this.prepaidCardPaginateTotalItem = this.PREPAID_CARD_LIST_DATA.paginate.totalItem
      let prepaidCardList = this.PREPAID_CARD_LIST_DATA.results
      this.prepaidCardList = []
      for (let i = 0, size = prepaidCardList.length; i < size; i++) {
        let userObj = {
          stt: i + 1 + (this.PREPAID_CARD_LIST_DATA.paginate.currentPage - 1) * this.PREPAID_CARD_LIST_DATA.paginate.pageSize,
          id: prepaidCardList[i].id,
          dateCreated: this.formatDate(prepaidCardList[i].date_created),
          code: prepaidCardList[i].code,
          points: parseInt(prepaidCardList[i].points),
          isShowTool: false,
          isLoading: false,
          name: prepaidCardList[i].name_card,
          status: prepaidCardList[i].status,
          type: prepaidCardList[i].type,
          active_date: prepaidCardList[i].active_date
        }
        this.prepaidCardList.push(userObj)
      }
    }
  },
  created () {
    this.getPrepaidCardList()
  },
  methods: {
    /**
     * Hover email
     */
    onHoverEmail: function (userItem) {
      userItem.isShowTool = true
    },
    /**
     * Leave email
     */
    onLeaveEmail: function (userItem) {
      userItem.isShowTool = false
    },
    /**
     * Parse date
     */
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.hasSystemRole(RoleType.ADMIN, this.GET_CURRENT_ACCOUNT)
    },
    isModerator: function () {
      return functionUtils.hasSystemRole(RoleType.MODERATOR, this.GET_CURRENT_ACCOUNT)
    },
    // Check Accountant
    isAccountant: function () {
      return functionUtils.hasSystemRole(RoleType.ACCOUNTANT, this.GET_CURRENT_ACCOUNT)
    },
    // Show modal input prepaid card info
    onShowModalInputPrepaidCardInfo: function (selectAll) {
      this.printAllPrepaidCard = selectAll
      this.$refs.inputPrepaidCardInfoModal.onShowModal(selectAll, this.prepaidCardPaginateTotalItem)
    },
    /**
     * Go screen print
     */
    onGoScreenPrint: function (prepaidCardInfo) {
      if (this.printAllPrepaidCard) {
        this.onGoPrintAllPrepaidCard(prepaidCardInfo)
      } else {
        this.onGoPrintPrepaidCardSelect(prepaidCardInfo)
      }
    },
    /**
     * print select
     */
    onGoPrintPrepaidCardSelect: function (prepaidCardInfo) {
      let prepaidCardIds = []
      for (let i = 0, size = this.selected.length; i < size; i++) {
        prepaidCardIds.push(this.selected[i].id)
      }
      let routeData = this.$router.resolve({ path: 'prints-prepaid-card', query: { prepaidCardIds: JSON.stringify(prepaidCardIds), prepaidCardInfo: JSON.stringify(prepaidCardInfo) } })
      window.open(routeData.href, '_blank')
    },
    /**
     * print all
     */
    onGoPrintAllPrepaidCard: function (prepaidCardInfo) {
      let startDate = null
      let endDate = null
      let toFormat = 'YYYY-MM-DD HH:mm:ss'
      if (!functionUtils.validateStringIsNull(this.dateFormatted)) {
        let start = moment(this.dateFormatted, 'DD/MM/YYYY')
        let end = moment(this.dateFormatted, 'DD/MM/YYYY')
        startDate = this.getStartOfDayUTC(start, toFormat)
        endDate = this.getEndOfDayUTC(end, toFormat)
      }
      let routeData = this.$router.resolve(
        {
          path: 'prints-prepaid-card',
          query: {
            printAllPrepaidCard: JSON.stringify(this.printAllPrepaidCard),
            startDate: startDate,
            endDate: endDate,
            status: this.prepaidCardSearch.statusValue,
            prepaidCardInfo: JSON.stringify(prepaidCardInfo)
          }
        })
      window.open(routeData.href, '_blank')
    },
    /**
     * Get date of birth
     */
    formatDate: function (dob) {
      if (!functionUtils.isNull(dob)) {
        return dateUtils.formatDate(dob, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE, dateUtils.STATIC_FORMAT_DATE)
      }
      return null
    },
    /**
     * Get prepaid card list
     */
    getPrepaidCardList: function () {
      let filter = {
        params: {
          page: this.prepaidCardPaginate.currentPage,
          search_text: this.prepaidCardSearch.searchText,
          status_value: this.prepaidCardSearch.statusValue
        }
      }
      let toFormat = 'YYYY-MM-DD HH:mm:ss'
      if (!functionUtils.validateStringIsNull(this.dateFormatted)) {
        let startDate = moment(this.dateFormatted, 'DD/MM/YYYY')
        let endDate = moment(this.dateFormatted, 'DD/MM/YYYY')
        filter.params['start_date'] = this.getStartOfDayUTC(startDate, toFormat)
        filter.params['end_date'] = this.getEndOfDayUTC(endDate, toFormat)
      }
      this.isLoading = true
      this.GET_PREPAID_CARD_LIST(filter).then(
        function () {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    getStartOfDayUTC: function (dateValue, toFormat) {
      return dateValue.startOf('day').utc().format(toFormat)
    },
    getEndOfDayUTC: function (dateValue, toFormat) {
      return dateValue.endOf('day').utc().format(toFormat)
    },
    /**
     * Recharge points in bulk
     */
    rechargePointsInBulk: function () {
      if (this.selected.length > 0) {
        let prepaidCardIds = []
        for (let i = 0, size = this.selected.length; i < size; i++) {
          prepaidCardIds.push(this.selected[i].id)
        }
        this.$refs.ChargePointsModal.onShowModal(null, this.prepaidCardPaginate.currentPage, prepaidCardIds)
      } else {
        this.ON_SHOW_TOAST({
          'message': this.$t('prepaidCard.plsChoosePrepaidCardNeedRecharge'),
          errorType: ToastType.ERROR
        })
      }
    },
    /**
     * Show modal charge points
     */
    onShowModalChargePoints: function (userItem) {
      this.$refs.ChargePointsModal.onShowModal(userItem, this.prepaidCardPaginate.currentPage)
    },
    /**
     * Show modal create prepaid card modal
     */
    onShowCreatePrepaidCardModal: function () {
      this.$refs.createPrepaidCardModal.onShowModal()
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.prepaidCardPaginate.currentPage = page
      this.getPrepaidCardList()
    },
    /**
     * Search
     */
    onSearch: function (event) {
      event.preventDefault()
      this.getPrepaidCardList()
    },
    // lock and unlock function
    updatePrepaidCard: function () {
      this.isLoading = true
      this.UPDATE_PREPAID_CARD(this.prepaidCardSelect).then(
        function () {
          this.isLoading = false
          this.getPrepaidCardList()
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    showModalUpdatePrepaidCard: function (prepaidCardId, status) {
      this.confirmModalTitle = status === this.prepaidCardStatus.LOCK ? this.$t('prepaidCard.lockCardTitle') : this.$t('prepaidCard.unlockCardTitle')
      this.prepaidCardSelect = {
        id: prepaidCardId,
        status: status
      }
      this.$refs.confirmModal.onShowModal()
    },
    // refund points
    showModalRefundPoints: function (userItem) {
      this.$refs.refundPointsModal.onShowModal(userItem, this.prepaidCardPaginate.currentPage)
    },
    onShowTransactionHistory: function (userId) {
      this.$refs.transactionHistoryListForUser.onShowModal(userId)
    },
    updateStatusPrepaidCardSelect: function (status) {
      if (this.selected.length === 0) {
        this.ON_SHOW_TOAST({
          'message': this.$t('prepaidCard.selectPrepaidCardLockOrUnlock'),
          errorType: ToastType.ERROR
        })
      } else {
        let prepaidCardIds = []
        for (let i = 0, size = this.selected.length; i < size; i++) {
          prepaidCardIds.push(this.selected[i].id)
        }
        this.lockAndUnlockCardIds = prepaidCardIds
        this.lockAndUnlockCardStatus = status
        this.updateStatusPrepaidCardSelectTitle = status === this.prepaidCardStatus.LOCK ? this.$t('prepaidCard.lockCardSelectTitle') : this.$t('prepaidCard.unlockCardSelectTitle')
        this.$refs.updateStatusPrepaidCardSelectModal.onShowModal()
      }
    },
    confirmUpdateStatusPrepaidCardSelect: function () {
      this.isLoading = true
      let data = {
        prepaidCardIds: this.lockAndUnlockCardIds,
        status: this.lockAndUnlockCardStatus
      }
      this.UPDATE_STATUS_PREPAID_CARD_SELECT(data)
        .then(
          function () {
            this.isLoading = false
            this.getPrepaidCardList()
          }.bind(this)
        ).catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    ...mapActions(['GET_PREPAID_CARD_LIST', 'UPDATE_PREPAID_CARD', 'ON_SHOW_TOAST', 'UPDATE_STATUS_PREPAID_CARD_SELECT'])
  }
}
</script>

<style>
.cursor-pointer {
  cursor: pointer !important;
}
</style>
