<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('prepaidCard.additionalInfo')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-layout wrap>
              <v-flex
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="mainTitle"
                  :label="$t('prepaidCard.mainTitle')"
                  :name="$t('prepaidCard.mainTitle')"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="subTitle"
                  :label="$t('prepaidCard.subTitle')"
                  :name="$t('prepaidCard.subTitle')"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-text-field
                  v-model="hotline"
                  :label="$t('prepaidCard.hotline')"
                  :name="$t('prepaidCard.hotline')"
                  type="text"
                />
              </v-flex>
              <v-flex
                xs12
                sm4
                md4>
                <v-checkbox
                  v-model="showInfo.logo"
                  :label="$t('common.logo')" />
              </v-flex>
              <v-flex
                v-if="printAllPrepaidCard"
                xs12
                sm12
                md12>
                <v-card
                  flat
                  color="transparent"
                >
                  <v-subheader>{{ $t('prepaidCard.rangeQueryTitle') }}</v-subheader>
                  <v-card-text>
                    <v-range-slider
                      v-model="rangeQuery"
                      :max="rangeQueryMax"
                      :min="rangeQueryMin"
                      hide-details
                      class="align-center"
                    >
                      <template v-slot:prepend>
                        <v-text-field
                          :value="rangeQuery[0]"
                          class="mt-0 pt-0"
                          hide-details
                          single-line
                          type="number"
                          style="width: 60px"
                          @change="$set(rangeQuery, 0, $event)"
                        />
                      </template>
                      <template v-slot:append>
                        <v-text-field
                          :value="rangeQuery[1]"
                          class="mt-0 pt-0"
                          hide-details
                          single-line
                          type="number"
                          style="width: 60px"
                          @change="$set(rangeQuery, 1, $event)"
                        />
                      </template>
                    </v-range-slider>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      valid: false,
      isShowModal: false,
      hotline: null,
      mainTitle: null,
      subTitle: null,
      showInfo: {
        logo: false
      },
      rangeQuery: [0, 1000],
      rangeQueryMax: 0,
      rangeQueryMin: 0,
      printAllPrepaidCard: false
    }
  },
  methods: {
    /**
     * Show modal confirm
     */
    onConfirm: function () {
      this.$emit('receivedPrepaidCardInfo', {
        hotline: this.hotline,
        mainTitle: this.mainTitle,
        subTitle: this.subTitle,
        showInfo: this.showInfo,
        rangeQueryStart: this.rangeQuery[0],
        rangeQueryEnd: this.rangeQuery[1]
      })
    },
    onShowModal: function (selectAll, prepaidCardTotalItem) {
      this.printAllPrepaidCard = selectAll
      this.rangeQueryMax = prepaidCardTotalItem
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
      // this.hotline = null
      // this.mainTitle = null
      // this.subTitle = null
    },
    ...mapActions(['ON_SHOW_TOAST'])
  }
}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
</style>
